import React from 'react'
import ConditionallyRender from './conditionally-render'

export default function StickyLeft({top,wrapper, left, children,leftClassName,ratio = 'grid-1-3' }) {
    return (
        <div className={'p4020 ' + wrapper}>
            <ConditionallyRender when={top}   >
            <div className='mb40 mt0 bt1 pt20'>
                {top  }  
            </div>
            </ConditionallyRender>
            <div className={'grid m-flex m-flex-col  ' + ratio + '   ' + leftClassName}>

                <div>
                    <div className='pos-stick top-60 '>
                        {left}
                    </div>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>


    )
}
