import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import ArrowLink from "../components/arrowLink"
import StickyLeft from './utils/sticky-left'
import Link from "../components/link"; 

export default function Team({ teamMembers, title }) {
    const content = (
    <div className='pr60'>
        <p className='mt0 m-mb20' dangerouslySetInnerHTML={{ __html:title}}></p>  
    </div>
    )
    return (
    <StickyLeft wrapper='m-pb20   ' top={ <h1 className='small mt0 mb0 uppercase book'>Our People</h1>} left={ content}>
        <div className='grid grid-3 gap-x-30 gap-y-60 m-grid-2 m-gap-x-10 m-gap-y-40 m-mb0 '>
            {teamMembers.map((teamMember, index) => {
                return <TeamMember key={index} teamMember={teamMember} />
            })}
        </div>
    </StickyLeft >
    )
}
function TeamMember({ teamMember }, index) {
    return (
        <Link className='link' to={'/team/' + teamMember.slug} key={index}>
            <GatsbyImage image={teamMember.image.gatsbyImageData} alt="Team Member" />
            <div className='pt15'>
                <p className='m0 m-h4'>{teamMember.name}</p>
                <p className='m0 op50 m-h4'>{teamMember.companyPosition}</p>
                <div className='mt20'>
                    <ArrowLink text="Read More" />
                </div>
            </div>
        </Link>

    )
}
