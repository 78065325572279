import * as React from "react"

const OurValues = ({values, mobile, email}) => (
  <div className='our--values p40 pt0'>
    <div className="flex m-flex-col"> 
      <div className="w-50 m-100">
        <p className="m0 m-mt20 book uppercase small">Our Approach</p>
        <div className="content fade--in h4 mt40 max-650 m-h4-16 " data-sal dangerouslySetInnerHTML={{ __html: values }}></div>
      </div>
      <div className="w-50 flex m-100 m-hide ">
        <div className="mla book pos-sticky mba">  
          <p className="m0 mb10">Let’s talk</p> 

          <p className={"m0 mb5 "+ (mobile ? '' : 'hide')}>M: <a href={'tel:'+mobile} className="ul-link inverse link">{mobile}</a></p>
          <p className="m0">E: <a href={'mailto:'+email} className="ul-link inverse link">{email}</a></p>
        </div>
      </div>
    </div>
  </div> 
) 
export default OurValues
