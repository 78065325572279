import { graphql } from 'gatsby'
import React, { useCallback } from 'react'
import CTA from '../components/ctaSmall'
import Layout from '../components/layout'
import ListItems from '../components/listItems'
import MoreProjects from '../components/moreProjects'
import OurValues from '../components/ourValues'
import ParallaxBanner from '../components/parallaxBanner'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import Team from '../components/team'

export default function AboutPage({ data }) {
	const team = data.team
	var page = data.allDatoCmsAboutPage.nodes[0]

	const adaptCoreValues = useCallback(values => {
		return values.map(({ value, description }) => {
			return {
				title: value,
				content: description,
			}
		})
	}, [])

	return (
		<Layout>
			<SEO title='About MOTIV' />
			<ParallaxBanner image={page.heroImage} title='About' version='true' text={page.heroHeading} />
			<div className='bg-black'>
				<Spacer className='m-hide mtn20' />
				<OurValues mobile={page.phone} email={page.email} values={page.ourValues} />
				<Spacer className='m-h-80' />
				<ListItems values={adaptCoreValues(page.values)} />
				<Spacer className='m-h-20' />
				<Team teamMembers={team?.nodes} title={page.teamText} />
				<Spacer className='m-h-60' />
				<MoreProjects projects={page.projects} />
				<CTA heading={page.ctaHeading} link={page.ctaLink} text={page.ctaText} />
			</div>
		</Layout>
	)
}

export const query = graphql`
	query AboutQuery {
		team: allDatoCmsTeam(sort: { fields: position, order: ASC }) {
			nodes {
				name
				slug
				companyPosition
				image {
					gatsbyImageData
				}
			}
		}
		allDatoCmsAboutPage {
			nodes {
				phone
				email
				ctaHeading
				ctaText
				ctaLink
				teamText
				heroHeading
				heroImage {
					gatsbyImageData
				}
				ourValues
				values {
					description
					value
				}
				projects {
					id
					name
					slug
					year
					id
					heroImage {
						gatsbyImageData
					}
				}
			}
		}
		allDatoCmsProject(limit: 3) {
			nodes {
				slug
				heroCaption
				heroImage {
					gatsbyImageData
				}
			}
		}
	}
`
